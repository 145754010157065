<template>
  <div>
    <div v-if="campaigns">
      <v-row
        v-for="campaign in campaigns"
        :key="campaign.id"
      >
        <v-col
          v-if="getMaterials(campaign.id)?.length > 0"
        >
          <StaticCard
            :campaign="campaign"
            :materials="getMaterials(campaign.id)"
            :country="company.country"
          />
        </v-col>
      </v-row>
    </div>
    <UpgradeCard
      v-if="isLight"
      :icon-spacing="100"
      :cta="$t('landingpages.download-tab.upgrade-card.static-tab.cta')"
      :title="$t('landingpages.download-tab.upgrade-card.static-tab.title')"
    >
      <v-col
        lg="10"
        cols="12"
      >
        <div class="text-body-1">
          {{ $t('landingpages.download-tab.upgrade-card.static-tab.body-texts.0', { readablePlatformName }) }}
        </div>
        <div class="my-4 upgrade-list">
          <div class="mb-2 text-body-1 font-weight-bold">
            {{ $t('landingpages.download-tab.upgrade-card.static-tab.body-texts.1') }}
          </div>
          <UpgradeItemList
            :items="[$t('labels.templates.upgrade-items.0'),$t('labels.templates.upgrade-items.1'),$t('labels.templates.upgrade-items.2'),$t('labels.templates.upgrade-items.3')]"
          />
        </div>
        <div class="text-body-1">
          {{ $t('landingpages.download-tab.upgrade-card.shared') }}
        </div>
      </v-col>
    </UpgradeCard>
  </div>
</template>

<script>
import StaticCard from './StaticCard.vue'
import UpgradeCard from '../UpgradeCard.vue'
import UpgradeItemList from '../UpgradeItemList.vue'
import brandingMixin from '@/mixins/branding'

export default {
  components: {
    StaticCard,
    UpgradeCard,
    UpgradeItemList
  },
  mixins: [brandingMixin],
  props: {
    getMaterials: {
      type: Function,
      required: true
    },
    company: {
      type: Object,
      required: true
    },
    campaigns: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    isLight () {
      return this.company?.productType === 'LIGHT'
    }
  }
}
</script>
