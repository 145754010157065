<template>
  <div>
    <div v-if="isLight">
      <UpgradeCard
        v-if="type === 'GUIDE'"
        :cta="$t('landingpages.download-tab.upgrade-card.guide.cta')"
        :title="$t('landingpages.download-tab.upgrade-card.guide.title')"
      >
        <v-col
          lg="10"
          cols="12"
        >
          <div class="text-body-1">
            {{ $t('landingpages.download-tab.upgrade-card.guide.body-texts.0', { readablePlatformName }) }}
          </div>
          <div class="my-4 upgrade-list">
            <div class="mb-2 text-body-1 font-weight-bold">
              {{ $t('landingpages.download-tab.upgrade-card.guide.body-texts.1') }}
            </div>
            <UpgradeItemList
              :items="bookableLps.map(lp => lp.name)"
            />
          </div>
          <div class="mb-1 text-body-1">
            {{ $t('landingpages.download-tab.upgrade-card.guide.body-texts.2') }}
          </div>
          <div class="text-body-1">
            {{ $t('landingpages.download-tab.upgrade-card.shared') }}
          </div>
        </v-col>
      </UpgradeCard>
      <UpgradeCard
        v-if="type === 'CHECKLIST'"
        :cta="$t('landingpages.download-tab.upgrade-card.checklist.cta')"
        :title="$t('landingpages.download-tab.upgrade-card.checklist.title')"
      >
        <v-col
          lg="10"
          cols="12"
        >
          <div class="text-body-1">
            {{ $t('landingpages.download-tab.upgrade-card.checklist.body-texts.0', { readablePlatformName }) }}
          </div>
          <div class="my-4 upgrade-list">
            <div class="mb-2 text-body-1 font-weight-bold">
              {{ $t('landingpages.download-tab.upgrade-card.checklist.body-texts.1') }}
            </div>
            <UpgradeItemList :items="bookableLps.map(lp => lp.name)" />
          </div>
          <div class="mb-1 text-body-1">
            {{ $t('landingpages.download-tab.upgrade-card.checklist.body-texts.2') }}
          </div>
          <div class="text-body-1">
            {{ $t('landingpages.download-tab.upgrade-card.shared') }}
          </div>
        </v-col>
      </UpgradeCard>
    </div>
    <div v-else>
      <v-row>
        <v-col>
          <AllInOneCard
            :company-slug="company.slug"
            :company-site-url="company.site.url"
            :name="readableType"
            :icon="icon"
          />
        </v-col>
      </v-row>
      <h3 class="my-4 overline grey--text text--darken-1">
        {{ $t('landingpages.download-tab.upgrade-card.single') }} {{ readableType }}
      </h3>
      <v-row
        v-for="lp in landingpages"
        :key="lp.slug"
      >
        <v-col>
          <LandingpageCard
            v-if="hasReadyMaterials(lp.id)"
            v-bind="lp"
            has-action
          >
            <template v-slot:content>
              <LandingpageCardContent
                v-bind="lp"
                :landingpage-slug="lp.slug"
                conversion-label="landingpages.download-tab.ordered"
                :materials-length="materialCount(lp.id)"
              />
            </template>
          </LandingpageCard>

          <UpsellingCard
            v-else
            v-bind="lp"
            :running="true"
            :is-booking-blocked="false"
          />
        </v-col>
      </v-row>
      <v-row
        v-for="lp in bookableLps"
        :key="lp.slug"
      >
        <v-col>
          <UpsellingCard
            v-bind="lp"
            :is-booking-blocked="isBookingBlocked"
            :submit="bookLandingpage"
            :price="price"
            :variant="company.variant"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import LandingpageCard from '../LandingpageCard'
import LandingpageCardContent from '../LandingpageCardContent'
import UpsellingCard from '../../upselling/UpsellingCard.vue'
import AllInOneCard from './AllInOneCard.vue'
import materialCount from '@/lib/materialCount'
import UpgradeCard from '../UpgradeCard.vue'
import UpgradeItemList from '../UpgradeItemList.vue'
import brandingMixin from '@/mixins/branding'

const ALL_IN_ONE_TYPE_MAP = {
  GUIDE: {
    name: 'landingpages.labels.guides',
    icon: 'mdi-book-open-variant'
  },
  CHECKLIST: {
    name: 'landingpages.labels.checklists',
    icon: 'mdi-clipboard-text'
  }
}
export default {
  components: {
    LandingpageCard,
    LandingpageCardContent,
    UpsellingCard,
    AllInOneCard,
    UpgradeCard,
    UpgradeItemList
  },
  mixins: [brandingMixin],
  props: {
    type: {
      type: String,
      default: ''
    },
    getMaterials: {
      type: Function,
      required: true
    },
    bookLandingpage: {
      type: Function,
      required: true
    },
    company: {
      type: Object,
      required: true
    },
    landingpages: {
      type: Array,
      required: true
    },
    bookableLps: {
      type: Array,
      required: true
    },
    price: {
      type: Object,
      default: () => ({})
    },
    isBookingBlocked: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    isLight () {
      return this.company?.productType === 'LIGHT' || this.company?.productType === 'PIONEER'
    },
    readableType () {
      return this.$t(ALL_IN_ONE_TYPE_MAP[this.type].name)
    },
    icon () {
      return ALL_IN_ONE_TYPE_MAP[this.type].icon
    }
  },

  methods: {
    hasReadyMaterials (lpId) {
      const materials = this.getMaterials(lpId)
      return materials.length > 0 && materials.every(material => material.hasFile)
    },
    materialCount (lpId) {
      const materials = this.getMaterials(lpId)
      return materialCount(materials)
    }
  }

}
</script>

<style scoped>
.design-wrapper {
  position: relative;
  height: 1px;
}

.design-elements{
  position: absolute;
  z-index: 1;
  pointer-events: none;
}

ul {
  list-style-type: none;
  padding-left: 0px;
}
</style>
