var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._l((_vm.landingpages),function(lp){return _c('v-row',{key:lp.slug},[_c('v-col',[_c('LandingpageCard',_vm._b({attrs:{"icon":lp.icon,"has-action":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('LandingpageCardContent',_vm._b({attrs:{"landingpage-slug":lp.slug,"conversion-label":_vm.conversionLabel(lp.type),"materials-length":_vm.isLight ? _vm.lightMaterials[lp.slug].length : _vm.materialCount(lp.id),"has-click-action":""},on:{"click":function($event){return _vm.handleClick(lp.type)}}},'LandingpageCardContent',lp,false))]},proxy:true}],null,true)},'LandingpageCard',lp,false))],1)],1)}),(_vm.isLight)?_c('UpgradeCard',{attrs:{"cta":_vm.$t('landingpages.tools-tab.upgrade-card.cta'),"title":_vm.$t('landingpages.tools-tab.upgrade-card.title')}},[_c('v-col',{attrs:{"lg":"10","cols":"12"}},[_c('div',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.$t('landingpages.tools-tab.upgrade-card.body-texts.0'))+" ")]),_c('div',{staticClass:"my-4 upgrade-list"},[_c('UpgradeItemList',{attrs:{"items":[
            _vm.$t('landingpages.tools-tab.upgrade-card.upgrade-items.0'),
            _vm.$t('landingpages.tools-tab.upgrade-card.upgrade-items.1'),
            _vm.$t('landingpages.tools-tab.upgrade-card.upgrade-items.2'),
            _vm.$t('landingpages.tools-tab.upgrade-card.upgrade-items.3'),
            _vm.$t('landingpages.tools-tab.upgrade-card.upgrade-items.4')
          ]}})],1),_c('div',{staticClass:"mb-1 text-body-1"},[_vm._v(" "+_vm._s(_vm.$t('landingpages.tools-tab.upgrade-card.body-texts.1'))+" ")]),_c('div',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.$t('landingpages.tools-tab.upgrade-card.body-texts.2'))+" ")])])],1):_c('div',_vm._l((_vm.bookableLps),function(lp){return _c('v-row',{key:lp.slug},[_c('v-col',[_c('UpsellingCard',{attrs:{"name":lp.name,"type":lp.type,"slug":lp.slug,"is-booking-blocked":_vm.isBookingBlocked,"submit":_vm.bookLandingpage,"price":_vm.price,"variant":_vm.company.variant}})],1)],1)}),1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }