<template>
  <LandingpageCard
    :name="campaign.name"
    has-action
  >
    <template v-slot:action>
      <Download
        outlined
        :count="readyMaterials.length"
        :path="`/materials/campaign/${campaign.id}`"
        :filename="`Materialien_${campaign.name}`"
      />
    </template>
    <template v-slot:content>
      <v-row>
        <template
          v-for="material in readyMaterials"
        >
          <v-col
            v-for="{file, previewFile} in material.files"
            :key="file.url"
            :cols="12"
            :sm="4"
            :md="3"
          >
            <MaterialCard
              :name="material.displayName"
              :url="file.cdnUrl"
              :thumbnail-url="previewFile && previewFile.cdnUrl"
              :icon="material.icon"
            />
          </v-col>
        </template>
      </v-row>
    </template>
  </LandingpageCard>
</template>

<script>
import Download from '../Download.vue'
import MaterialCard from '../MaterialCard'
import LandingpageCard from '../LandingpageCard.vue'

const ICONS = {
  STATIC: 'img/material-static-preview.svg',
  GENERATED: 'img/material-video-preview.svg'
}

export default {
  components: { MaterialCard, Download, LandingpageCard },
  props: {
    campaign: { type: Object, required: true },
    materials: { type: Array, required: true },
    country: { type: String, required: true }
  },
  computed: {
    readyMaterials () {
      return this.materials
        .filter(({ hasFile }) => hasFile)
        .map((material) => ({
          ...material,
          ...(!material.files[0].previewFile && { icon: ICONS[material.mode] })
        }))
    }
  }
}
</script>
