<template>
  <v-container class="layout-container">
    <download-area />
    <overview />
  </v-container>
</template>

<script>
import Overview from '@/modules/landingpages'
import DownloadArea from '@/modules/landingpages/DownloadArea'

export default {
  components: {
    Overview,
    DownloadArea
  }
}
</script>
