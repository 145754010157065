<template>
  <div class="pb-10 pa-5">
    <div class="d-flex justify-space-between">
      <p class="text-h6 font-weight-regular d-flex align-center">
        <v-icon class="mr-2">
          mdi-information
        </v-icon> {{ $t('labels.hint') }}
      </p>
      <v-btn
        icon
        @click="cancel"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>

    <span
      v-if="hasOpenRequirements"
      class="font-weight text-body-1 "
    >
      {{ $t('upselling.confirmation.title') }}:
      <span
        v-for="(item, i) in requirements"
        :key="i"
      >
        {{ item }}
        {{ i >= requirements.length-1 ? '':',' }}
      </span>
    </span>

    <span
      v-else
      class="font-weight text-body-1 "
    >
      {{ infoText }} <br>
      {{ $t('upselling.confirmation.body-texts.0') }}
      {{ $t('upselling.confirmation.body-texts.1') }}
      {{ durationText }}
    </span>

    <div class="justify-end mt-4 align-center flex-column d-flex flex-md-row">
      <span class="mr-md-2 font-weight-regular text-subtitle-1">
        {{ $t('upselling.confirmation.body-texts.2') }}
      </span>
      <v-btn
        color="primary"
        :disabled="hasOpenRequirements"
        @click="submit"
      >
        {{ $t('buttons.upselling.confirmation.cta') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import UpsellingType from './enums/UpsellingType'

const INFO = {
  [UpsellingType.GUIDE]: 'upselling.confirmation.info-activation',
  [UpsellingType.CHECKLIST]: 'upselling.confirmation.info-activation',
  [UpsellingType.QUIZZARD]: 'upselling.confirmation.info-activation',
  [UpsellingType.CAMPAIGN]: 'upselling.confirmation.info-activation-once'
}

export default {
  name: 'Confirmation',

  props: {
    type: { type: String, default: UpsellingType.CHECKLIST },
    price: { type: String, default: '' },
    requirements: { type: Array, default: () => [] },
    cancel: { type: Function, default: () => {} },
    submit: { type: Function, default: () => {} }
  },

  computed: {
    infoText () {
      return `${this.$t(INFO[this.type])} ${this.price}${this.type !== UpsellingType.CAMPAIGN ? this.$t('upselling.confirmation.monthly') : ''}`
    },
    durationText () {
      return this.type !== UpsellingType.CAMPAIGN ? this.$t('upselling.confirmation.run-time') : ''
    },
    requiredLandingpages () {
      return this.feature.landingpages.map(landingpage => landingpage.landingpageSlug)
    },
    hasOpenRequirements () {
      return this.requirements.length > 0
    }
  }
}
</script>

<style scoped>

</style>
