const UpsellingType = Object.freeze({
  GUIDE: 'GUIDE',
  CHECKLIST: 'CHECKLIST',
  CAMPAIGN: 'CAMPAIGN',
  QUIZZARD: 'QUIZZARD',
  LIFE_ANNUITY: 'LIFE_ANNUITY',
  PLOT_VALUATION: 'PLOT_VALUATION'
})

export default UpsellingType
