<template>
  <div>
    <v-row
      v-for="lp in landingpages"
      :key="lp.slug"
    >
      <v-col>
        <LandingpageCard
          v-bind="lp"
          :icon="lp.icon"
          has-action
        >
          <template v-slot:content>
            <LandingpageCardContent
              v-bind="lp"
              :landingpage-slug="lp.slug"
              :conversion-label="conversionLabel(lp.type)"
              :materials-length="isLight ? lightMaterials[lp.slug].length : materialCount(lp.id)"
              has-click-action
              @click="handleClick(lp.type)"
            />
          </template>
        </LandingpageCard>
      </v-col>
    </v-row>

    <UpgradeCard
      v-if="isLight"
      :cta="$t('landingpages.tools-tab.upgrade-card.cta')"
      :title="$t('landingpages.tools-tab.upgrade-card.title')"
    >
      <v-col
        lg="10"
        cols="12"
      >
        <div class="text-body-1">
          {{ $t('landingpages.tools-tab.upgrade-card.body-texts.0') }}
        </div>
        <div class="my-4 upgrade-list">
          <UpgradeItemList
            :items="[
              $t('landingpages.tools-tab.upgrade-card.upgrade-items.0'),
              $t('landingpages.tools-tab.upgrade-card.upgrade-items.1'),
              $t('landingpages.tools-tab.upgrade-card.upgrade-items.2'),
              $t('landingpages.tools-tab.upgrade-card.upgrade-items.3'),
              $t('landingpages.tools-tab.upgrade-card.upgrade-items.4')
            ]"
          />
        </div>
        <div class="mb-1 text-body-1">
          {{ $t('landingpages.tools-tab.upgrade-card.body-texts.1') }}
        </div>
        <div class="text-body-1">
          {{ $t('landingpages.tools-tab.upgrade-card.body-texts.2') }}
        </div>
      </v-col>
    </UpgradeCard>
    <div v-else>
      <v-row
        v-for="lp in bookableLps"
        :key="lp.slug"
      >
        <v-col>
          <UpsellingCard
            :name="lp.name"
            :type="lp.type"
            :slug="lp.slug"
            :is-booking-blocked="isBookingBlocked"
            :submit="bookLandingpage"
            :price="price"
            :variant="company.variant"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import LandingpageCard from '../LandingpageCard'
import UpsellingCard from '@/modules/upselling/UpsellingCard.vue'
import { TOOLS } from '@/lib/tools'
import LandingpageCardContent from '../LandingpageCardContent.vue'
import { persistFilter, resetAllFilters } from '@/modules/leads/helper/filter'
import materialCount from '@/lib/materialCount'
import UpgradeCard from '../UpgradeCard.vue'
import UpgradeItemList from '../UpgradeItemList.vue'
import lightMaterials from '@/lib/lightMaterials' // temporary solution for light-product, see comment in lightMaterials.js

export default {
  components: {
    LandingpageCard,
    UpsellingCard,
    LandingpageCardContent,
    UpgradeCard,
    UpgradeItemList
  },

  props: {
    getMaterials: {
      type: Function,
      required: true
    },
    bookLandingpage: {
      type: Function,
      required: true
    },
    company: {
      type: Object,
      required: true
    },
    landingpages: {
      type: Array,
      required: true
    },
    bookableLps: {
      type: Array,
      required: true
    },
    isBookingBlocked: {
      type: Boolean,
      required: true
    },
    price: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      lightMaterials
    }
  },

  computed: {
    isLight () {
      return this.company?.productType === 'LIGHT'
    }
  },

  methods: {
    conversionLabel (type) {
      return TOOLS.find(tool => tool.type === type).action
    },
    handleClick (type) {
      resetAllFilters()

      const tool = TOOLS.find(tool => tool.type === type)
      persistFilter(tool.key, true)

      this.$router.push('/leads?filter')
    },
    materialCount (lpId) {
      const materials = this.getMaterials(lpId)
      return materialCount(materials)
    }
  }
}
</script>
