<template>
  <div class="pb-10 pa-5">
    <div class="d-flex justify-space-between">
      <p class="text-h6 font-weight-regular d-flex align-center">
        <v-icon class="mr-2">
          mdi-lock
        </v-icon> {{ $t('labels.hint') }}
      </p>
    </div>

    <span class="font-weight text-body-1 ">
      {{ $t('upselling.blocked.body-texts.0', { infoText }) }}
      {{ $t('upselling.blocked.body-texts.1') }} <a :href="email">Support</a> {{ $t('upselling.blocked.body-texts.2') }}
    </span>
  </div>
</template>

<script>
import featureNames from '@/lib/featureNames'
import UpsellingType from './enums/UpsellingType'

const INFO = {
  [UpsellingType.GUIDE]: 'upselling.blocked.info.guide',
  [UpsellingType.QUIZZARD]: 'upselling.blocked.info.quizzard',
  [UpsellingType.CHECKLIST]: 'upselling.blocked.info.checkklist'
}

export default {
  name: 'Blocked',

  props: {
    type: { type: String, default: UpsellingType.CHECKLIST }
  },

  computed: {
    infoText () {
      return INFO[this.type] ? `${this.$t(INFO[this.type])}` : this.$t(INFO[UpsellingType.QUIZZARD])
    },
    email () {
      return `mailto:${this.$features.feature(featureNames.SUPPORT_CONTACT).config.email}`
    }
  }
}
</script>

<style scoped>

</style>
