<template>
  <div v-if="company && materials && pricesForCompany && availableCampaigns">
    <v-card
      outlined
      class="px-2 pt-2 px-md-4"
    >
      <v-tabs
        v-model="tab"
        show-arrows
      >
        <v-tab
          replace
          to="#tools"
        >
          {{ $t('landingpages.labels.tools') }} ({{ count(tools, 'tools') }})
        </v-tab>
        <v-tab
          replace
          to="#guides"
        >
          {{ $t('landingpages.labels.guides') }} ({{ count(guides, 'guides') }})
        </v-tab>
        <v-tab
          v-if="showChecklistTab"
          replace
          to="#checklists"
        >
          {{ $t('landingpages.labels.checklists') }} ({{ count(checklists, 'checklists') }})
        </v-tab>
        <v-tab
          v-if="$features.feature(featureNames.CAMPAIGN)?.isActive"
          replace
          to="#campaigns"
        >
          {{ $t('landingpages.labels.campaigns') }} ({{ countForStaticLightCampaign() }})
        </v-tab>
        <v-tab
          v-if="showTemplateTab"
          replace
          to="#templates"
        >
          {{ $t('landingpages.labels.templates') }} ({{ staticCampaigns.length }})
        </v-tab>
        <v-tab
          v-if="$features.feature(featureNames.NEWSFLASH)?.isActive"
          replace
          to="#newsflash"
        >
          {{ $t('landingpages.labels.newsflash') }} ({{ newsflashCampaigns.length }})
        </v-tab>
      </v-tabs>

      <v-divider />

      <v-tabs-items
        v-model="tab"
        class="py-4 transparent"
      >
        <v-tab-item value="tools">
          <ToolsTab
            :company="company"
            :landingpages="tools"
            :get-materials="getNonCampaignMaterialsForLandinpage"
            :bookable-lps="bookableLps('tools')"
            :book-landingpage="bookLandingpage"
            :is-booking-blocked="isBookingBlocked"
            :price="price('landingpage')"
          />
        </v-tab-item>

        <v-tab-item value="guides">
          <DownloadTab
            type="GUIDE"
            :company="company"
            :price="price('landingpage')"
            :landingpages="guides"
            :bookable-lps="bookableLps('guides')"
            :is-booking-blocked="isBookingBlocked"
            :get-materials="getNonCampaignMaterialsForLandinpage"
            :book-landingpage="bookLandingpage"
          />
        </v-tab-item>

        <v-tab-item
          v-if="showChecklistTab"
          value="checklists"
        >
          <DownloadTab
            type="CHECKLIST"
            :company="company"
            :price="price('checklist')"
            :landingpages="checklists"
            :bookable-lps="bookableLps('checklists')"
            :is-booking-blocked="isBookingBlocked"
            :get-materials="getNonCampaignMaterialsForLandinpage"
            :book-landingpage="bookLandingpage"
          />
        </v-tab-item>

        <v-tab-item
          v-if="$features.feature(featureNames.CAMPAIGN)?.isActive"
          value="campaigns"
        >
          <CampaignTab
            :price="price('campaign')"
            :company="company"
            :campaigns="realCampaigns"
            :booked-campaigns="bookedCampaigns"
            :landingpages="landingpages"
            :landingpage-templates="landingpageTemplates"
            :is-booking-blocked="isBookingBlocked"
            :get-materials="getCampaignMaterials"
          />
        </v-tab-item>
        <v-tab-item
          v-if="showTemplateTab"
          value="templates"
        >
          <StaticTab
            :company="company"
            :campaigns="staticCampaigns"
            :get-materials="getCampaignMaterials"
          />
        </v-tab-item>
        <v-tab-item
          v-if="$features.feature(featureNames.NEWSFLASH)?.isActive"
          value="newsflash"
        >
          <CampaignTab
            :price="price('campaign')"
            :company="company"
            :campaigns="newsflashCampaigns"
            :booked-campaigns="bookedCampaigns"
            :landingpages="landingpages"
            :landingpage-templates="landingpageTemplates"
            :is-booking-blocked="isBookingBlocked"
            :get-materials="getCampaignMaterials"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import LANDINGPAGE_TEMPLATES_FOR_COMPANY from '@/queries/LandingpageTemplatesForCompany.gql'
import PRICES from '../../queries/PricesForCompany.gql'
import LANDINGPAGES from './Landingpages.gql'
import AVAILABLE_CAMPAIGNS from './AvailableCampaigns.gql'
import MATERIALS from './materials.gql'
import BOOK_LANDINGPAGE from './BookLandingpage.gql'
import BOOKED_CAMPAIGNS from '@/queries/BookedCampaigns.gql'
import { TOOLS } from '@/lib/tools'
import ToolsTab from './tools/ToolsTab.vue'
import DownloadTab from './download/DownloadTab.vue'
import CampaignTab from './campaigns/CampaignTab.vue'
import StaticTab from './static/StaticTab.vue'
import featureNames from '@/lib/featureNames'
import brandingMixin from '@/mixins/branding'
import CampaignType from './campaigns/enums/CampaignTypes'
import { campaigns as lightCampaigns } from '@/lib/lightMaterials' // temporary solution for light-product, see comment in lightMaterials.js
import { isLightUser } from '@/lib/productTypes'
import feature from '@/mixins/feature'

const LP_TYPE_MAP = {
  tools: ['VALUATION', 'RETURN_CALL', 'LIFE_ANNUITY', 'PARTIAL_SALE', 'PLOT_VALUATION', 'QUIZZARD', 'PROPERTY_REQUEST'],
  guides: ['GUIDE'],
  checklists: ['CHECKLIST']
}

export default {
  components: {
    ToolsTab,
    DownloadTab,
    CampaignTab,
    StaticTab
  },
  mixins: [brandingMixin, feature],

  data: () => ({
    company: null,
    materials: [],
    landingpageTemplates: [],
    tab: 'tools',
    fetchLandingpageInterval: null,
    fetchLandingpageTimeout: null,
    availableCampaigns: [],
    bookedCampaigns: []
  }),

  apollo: {
    company: {
      query: LANDINGPAGES,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      }
    },
    pricesForCompany: {
      query: PRICES,
      variables () {
        return {
          input: {
            companyId: this.$auth.user.companyId,
            readableIds: this.availableProductReadableIds
          }
        }
      }
    },
    materials: {
      query: MATERIALS,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      }
    },
    landingpageTemplates: {
      query: LANDINGPAGE_TEMPLATES_FOR_COMPANY,
      variables () {
        return {
          input: {
            companyId: this.$auth.user.companyId,
            onlyDefault: true
          }
        }
      }
    },
    availableCampaigns: {
      query: AVAILABLE_CAMPAIGNS,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      }
    },
    bookedCampaigns: {
      query: BOOKED_CAMPAIGNS,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      }
    }
  },

  computed: {
    availableProductReadableIds () {
      return ['landingpage', 'checklist', `campaign-${this.company?.productType?.toLowerCase()}`]
    },

    landingpages () {
      return (this.company.landingpages || [])
        .filter(({ slug }) =>
          this.$features.feature('lp-' + slug)?.isActive
        )
        .sort((a, b) => new Date(b.template.createdAt) - new Date(a.template.createdAt))
    },

    isBookingBlocked () {
      if (!this.$features.feature(featureNames.CONTRACT)?.isActive) {
        return false
      }
      const currentDate = new Date()
      if (!this.company.currentContract.endDate) {
        return false
      }
      const endDate = new Date(this.company.currentContract.endDate)
      const isInNext2Months = Math.floor(Math.abs(endDate - currentDate) / 86400000) < 60
      return (this.company.state.value.ACTIVE === 'CANCELLED' && isInNext2Months)
    },

    showTemplateTab () {
      return this.$features.feature(featureNames.STATIC_CAMPAIGN)?.isActive
    },

    showChecklistTab () {
      return this.$features.feature(featureNames.LANDINGPAGE)?.config?.hasChecklists
    },

    tools () {
      return this.filterType(this.landingpages, LP_TYPE_MAP.tools)
        .map(lp => ({ ...lp, ...TOOLS.find(tool => tool.type === lp.type) }))
        .filter(lp => lp.order)
        .sort((a, b) => a.order - b.order)
    },
    guides () {
      return this.filterType(this.landingpages, LP_TYPE_MAP.guides)
    },
    checklists () {
      return this.filterType(this.landingpages, LP_TYPE_MAP.checklists)
    },
    realCampaigns () {
      return this.availableCampaigns.filter(campaign => campaign.type === CampaignType.DEFAULT)
    },
    staticCampaigns () {
      return this.availableCampaigns.filter(campaign => campaign.type === CampaignType.STATIC)
    },
    newsflashCampaigns () {
      return this.availableCampaigns.filter(campaign => campaign.type === CampaignType.NEWSFLASH)
    },

    toolTemplates () {
      return this.filterType(this.landingpageTemplates, LP_TYPE_MAP.tools)
    },
    guideTemplates () {
      return this.filterType(this.landingpageTemplates, LP_TYPE_MAP.guides)
    },
    checklistTemplates () {
      return this.filterType(this.landingpageTemplates, LP_TYPE_MAP.checklists)
    },
    isLight () {
      return isLightUser(this.$auth.user)
    }
  },

  unmounted () {
    if (this.fetchLandingpageInterval) {
      clearInterval(this.fetchLandingpageInterval)
    }
    if (this.fetchLandingpageTimeout) {
      clearTimeout(this.fetchLandingpageTimeout)
    }
  },

  methods: {
    isCampaignAvailableForCountry ({ landingpages }) {
      return landingpages.length === 0 || landingpages.some(({ country }) => country === this.company?.country)
    },

    count (template, type) {
      const activeCount = template?.length || 0
      const bookableCount = this.bookableLps(type)?.length || 0
      return `${activeCount}/${activeCount + bookableCount}`
    },

    countForStaticLightCampaign () {
      const staticCampaignsForLight = this.isLight ? Object.keys(lightCampaigns).length : 0
      return `${this.bookedCampaigns.length + staticCampaignsForLight} / ${this.realCampaigns.length + staticCampaignsForLight}`
    },

    bookableLps (type) {
      return this.filterType(this.landingpageTemplates, LP_TYPE_MAP[type]).filter(lp => this.isUpsellingEnabled(lp)).filter((lp) => !this.isLpExisting(lp))
    },

    isLpExisting ({ slug }) {
      return this.landingpages.find(lp => lp.slug === slug)
    },

    isUpsellingEnabled (lp) {
      return this.$features.feature(`lp-${lp.slug}`).isUpsellingEnabled
    },

    filterType (items, types) {
      return items.filter(lp => types.includes(lp.type))
    },

    price (type) {
      return this.pricesForCompany.find(price => price.readableId.includes(type))
    },

    getNonCampaignMaterialsForLandinpage (lpId) {
      return this.materials.filter(({ landingpageId, type }) => landingpageId === lpId && type !== 'CAMPAIGN')
    },

    getCampaignMaterials (campaignId) {
      return this.materials.filter((material) => material.campaignId === campaignId)
    },

    startFetchLandingpageInterval () {
      // for 15 minutes fetch all LPs every minute
      const timeout = 1000 * 60 * 15
      this.fetchLandingpageInterval = setInterval(() => {
        this.$apollo.queries.company.refetch()
        this.$apollo.queries.landingpageTemplates.refetch()
        this.$apollo.queries.materials.refetch()
      }, 60000)

      this.fetchLandingpageTimeout = setTimeout(() => {
        clearInterval(this.fetchLandingpageInterval)
      }, timeout)
    },

    async bookLandingpage (type, slug) {
      try {
        await this.$apollo.mutate({
          mutation: BOOK_LANDINGPAGE,
          variables: {
            input: {
              companyId: this.$auth.user.companyId,
              slug: slug,
              type
            }
          }
        })
        this.startFetchLandingpageInterval()
      } catch (e) {
        throw new Error('GraphQL Error: ', e)
      }
    }
  }

}
</script>
