<template>
  <div>
    <BaseHeader
      :title="$t('navigation.labels.landingpages')"
      :style-variant="2"
    >
      <template v-slot:design>
        <HeaderDesign />
      </template>

      <template v-slot:desc>
        <p>
          {{ isFeatureActive(featureNames.CONTRACT) ? $t('landingpages.download-area.has-contract-title') : $t('landingpages.download-area.no-contract-title') }}
        </p>
      </template>
      <template v-slot:content>
        <div class="pb-2">
          <v-btn
            v-if="landingpageOverviewUrl"
            class="ml-0"
            text
            color="primary"
          >
            <a
              class="info-link"
              target="_blank"
              rel="noopener noreferrer"
              :href="landingpageOverviewUrl"
            >{{ $t('landingpages.download-area.overview') }}</a>
            <v-icon right>
              mdi-view-grid-outline
            </v-icon>
          </v-btn>

          <Feature
            v-slot="{feature}"
            :feature-slug="featureNames.MATERIAL_GUIDE"
          >
            <v-btn
              class="ml-0"
              text
              color="primary"
            >
              <a
                class="info-link"
                target="_blank"
                rel="noopener noreferrer"
                :href="feature.config.guideUrl"
              >{{ $t('landingpages.download-area.guide-url') }}</a>
              <v-icon right>
                mdi-link
              </v-icon>
            </v-btn>
          </Feature>

          <v-btn
            v-if="hasWidgets"
            class="ml-0"
            color="primary"
            text
            @click="downloadImplementationData"
          >
            {{ $t('landingpages.download-area.download') }}
            <v-icon right>
              mdi-download
            </v-icon>
          </v-btn>

          <Download
            v-if="hasMaterialsFeature"
            text
            :title="$t('landingpages.download-area.all-materials')"
            path="/materials/all"
            filename="Materialien"
          />
        </div>
      </template>
    </BaseHeader>
  </div>
</template>

<script>
import { exportImplementationData } from '@/lib/export'
import Download from './Download.vue'
import BaseHeader from '@/components/BaseHeader.vue'
import featureMixin from '@/mixins/feature'
import LANDINGPAGE_OVERVIEW from './LandingpageOverview.gql'
import HeaderDesign from './HeaderDesign.vue'

export default {
  components: { Download, BaseHeader, HeaderDesign },
  mixins: [featureMixin],
  computed: {
    landingpageOverviewUrl () {
      if (!this.hasHomepage) return ''
      if (!this.company?.site) return ''
      return this.company?.site.url
    },

    hasMaterialsFeature () {
      const feature = this.getFeature(this.featureNames.LANDINGPAGE)
      return feature.isActive && (feature.config.hasMaterials || feature.config.hasStaticMaterials)
    },
    hasHomepage () {
      return this.isFeatureActive(this.featureNames.LANDINGPAGE) && this.getFeature(this.featureNames.LANDINGPAGE)?.config.hasHomepage
    },
    hasWidgets () {
      const feature = this.$features.feature(this.featureNames.CMS)
      return !feature.isActive || feature.config.hasWidgets
    }
  },
  methods: {
    async downloadImplementationData () {
      await exportImplementationData(this.$auth.user.companyId)
    }
  },
  apollo: {
    company: {
      query: LANDINGPAGE_OVERVIEW,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      }
    }
  }
}
</script>

<style scoped>
.info-link{
  text-decoration: none;
}
</style>
