<template>
  <LandingpageCard
    :name="name"
    :template="template"
  >
    <template v-slot:content>
      <Upselling
        :type="type"
        :slug="slug"
        :requirements="requirements"
        :running="running"
        :submit="submit"
        :is-booking-blocked="isBookingBlocked"
        :price="price"
        :variant="variant"
      />
    </template>
  </LandingpageCard>
</template>

<script>
import LandingpageCard from '../landingpages/LandingpageCard.vue'
import Upselling from '../upselling'

export default {
  components: {
    Upselling,
    LandingpageCard
  },

  props: {
    slug: { type: String, default: '' },
    name: { type: String, default: '' },
    isBookingBlocked: { type: Boolean, default: true },
    type: { type: String, default: '' },
    requirements: { type: Array, default: () => [] },
    submit: { type: Function, default: () => {} },
    running: { type: Boolean, default: false },
    price: { type: Object, default: () => {} },
    variant: { type: String, default: '' },
    template: { type: Object, default: () => {} }
  }
}
</script>
