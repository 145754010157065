<template>
  <v-card
    outlined
    class="d-flex flex-column"
  >
    <router-link
      :is="hasAction && !!slug ? 'router-link': 'span'"
      class="text-decoration-none"
      :to="`landingpages/${slug}`"
    >
      <v-card-title class="d-flex">
        <v-icon
          v-if="icon"
          class="mr-2"
        >
          {{ icon }}
        </v-icon>
        <h4 class="font-weight-regular text--primary">
          {{ name }}
        </h4>
        <v-badge
          v-if="isNewsworthy"
          content="Neu!"
          color="orange"
          class="ml-4"
        />
        <information-button
          v-if="hasInformationButton"
          :title="details.title"
          :content="details.content"
          class="ml-2"
        />
        <v-spacer />

        <div
          v-if="hasAction"
          class="hidden-xs-only"
        >
          <slot name="action">
            <!-- Fallback Action -->
            <v-btn
              width="150"
              class="items-center d-flex "
              color="primary"
              :to="`/landingpages/${slug}`"
            >
              {{ $t('labels.more') }}
              <v-icon
                right
                dark
              >
                mdi-arrow-right
              </v-icon>
            </v-btn>
          </slot>
        </div>
      </v-card-title>
    </router-link>

    <v-divider />

    <v-card-text class="content text-body-1">
      <slot name="content" />
    </v-card-text>

    <v-card-actions
      v-if="hasAction"
      class="mb-2 hidden-sm-and-up"
    >
      <slot name="action">
        <v-btn
          class="items-center w-full d-flex"
          color="primary"
          :to="`/landingpages/${slug}`"
        >
          {{ $t('labels.more') }}
          <v-icon
            right
            dark
          >
            mdi-arrow-right
          </v-icon>
        </v-btn>
      </slot>
    </v-card-actions>
  </v-card>
</template>

<script>
import InformationButton from '@/components/InformationButton.vue'

const DETAILS = {
  'leibrenten-check': {
    title: 'Der Leibrenten-Check bringt Sie ins Gespräch.',
    content: `Verrenten und wohnen bleiben anstatt verkaufen und ausziehen. Ein Modell, das für viele betagte Eigentümer verlockend klingt.
    Mit unserem neuesten Werkzeug greifen Sie das Thema auf.<br><br>
  Achtung: Unser Leibrenten-Check liefert bewusst keine Zahlen. Er dient Ihnen als Türöffner und sammelt für Sie Daten von potenziellen Kunden.<br><br>
  Sie entscheiden frei, wie Sie die gesammelten Daten nutzen möchten: individuelle Angebote berechnen, alternative Optionen mit dem Kunden besprechen oder an eventuelle Kooperationspartner weitervermitteln.<br><br>
  Dieser Köder ermöglicht es Ihnen mit Ihren Kunden ins Gespräch zu kommen. Viel Erfolg!`
  },
  'teilverkaufs-check': {
    title: 'Der Teilverkaufs-Check bringt Sie ins Gespräch.',
    content: `Immobilienanteil verkaufen und wohnen bleiben anstatt komplett verkaufen und ausziehen. Ein Modell, das für viele betagte Eigentümer verlockend klingt.
    Mit unserem neuesten Werkzeug greifen Sie das Thema auf.<br><br>
  Achtung: Unser Teilverkaufs-Check liefert bewusst keine Zahlen. Er dient Ihnen als Türöffner und sammelt für Sie Daten von potenziellen Kunden.<br><br>
  Sie entscheiden frei, wie Sie die gesammelten Daten nutzen möchten: individuelle Angebote berechnen, alternative Optionen mit dem Kunden besprechen oder an eventuelle Kooperationspartner weitervermitteln.<br><br>
  Dieser Köder ermöglicht es Ihnen mit Ihren Kunden ins Gespräch zu kommen. Viel Erfolg!`
  }
}

export default {
  components: { InformationButton },
  props: {
    id: { type: String, default: '' },
    slug: { type: String, default: '' },
    name: { type: String, default: '' },
    icon: { type: String, default: '' },
    template: { type: Object, default: () => ({}) },
    hasAction: { type: Boolean, default: false }
  },
  computed: {
    hasInformationButton () {
      return Boolean(DETAILS[this.slug])
    },

    details () {
      return DETAILS[this.slug]
    },

    isNewsworthy () {
      // Older landingpage templates don't have a createdAt date.
      if (!this.template.createdAt) {
        return false
      }

      const currentDate = new Date()
      const createdDate = new Date(this.template.createdAt)

      const diffInMilliseconds = currentDate - createdDate
      const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24))

      return diffInDays <= 30
    }
  }
}
</script>

<style scoped>
.content{
  overflow: hidden;
}
</style>
